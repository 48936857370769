body {
  background-color: #f5f5f5;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root>* {
  flex: 1;
  width: 100%;
}

.layoutcol {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
}



.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.actionscontainer {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-wrap: wrap;
}

.actionsitem {
  margin: 15px;
  height: 70px;
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

a,
button {
  cursor: pointer;
}

.tableitem {
  margin: 10px;
  padding: 15px;
  border: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 70px;
  border: 1px solid #ccc;
  width: 250px;
  cursor: pointer;
  background: #fff;
  border-radius: 3px;
}

.tableitem-occuper {
  background: #58ba48;
  color: #fff;
}

.listGroupes {
  display: flex;
  overflow-x: auto;
}

.groupitem {
  margin: 5px;
  padding: 15px;
  border: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  border: 1px solid #58ba48;
  width: 200px;
  cursor: pointer;
  background: #fff;
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
}

.groupitem1 {
  border: 1px solid #c1d834;
}

.groupitem.active1 {
  background: #58ba48;
  color: #ffff;
  min-width: 150px;
  max-width: 150px;
}

.groupitem.active2 {
  background: #007bff;
  border: 1px solid #007bff;
  color: #ffff;
  min-width: 150px;
  max-width: 150px;
}

.groupitem.active {
  background: #c1d834;
  color: #ffff;
}

.commandecontainer {
  min-width: 312px;
  border: 1px dashed #007bff;
  background: #efefef;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  min-height: 400px;
  position: relative;
}

.consumercontainer {
  flex: 1;
  border: 1px dashed #c1d834;
  margin-left: 5px;
  padding: 5px;
}

.commandefooter {
  padding: 15px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.commandeheader {
  background: #fafafa;
  padding: 7px 15px;
  font-weight: bold;
  font-size: 20px;
}

@media only screen and (max-width:480px) {
  .commandecontainer {
    display: none;
  }

  .showcmd .commandecontainer {
    display: flex;
  }

  .showcmd .consumercontainer {
    display: none;
  }
}

.servablelist {
  display: flex;
  flex-wrap: wrap;
}

.servableitem {
  flex: 1;
  width: 30%;
  min-width: 200px;
  max-width: 250px;
  margin: 5px;
  padding: 5px;
  height: 150px;
  background: #fff;
  border: 1px solid #c1d834;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  box-sizing: border-box !important;
}

.servableitem-selected {
  border: 4px solid #58ba48;
}

.servableitem>img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.servableitem>span {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 7px;
}

.servableitem>small {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1000;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #58ba48;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}


.groupeitem {
  margin: 1px;
  background: #fff;
}

.platitem {
  padding: 5px;
}

.platitem {
  border-top: 1px dashed #ccc;
}

.listcommandeshowposte {
  display: flex;
  overflow-x: auto;
  align-items: flex-start;
  justify-content: flex-start;
}

.CommandeShow {
  width: 353px;
  max-width: 400px;
  min-width: 353px;
  padding: 10px;
  margin: 10px;
  border: 1px dashed #ccc;
  background: #fff;
}

.CommandeShow-active {
  border: 1px dashed green;
  /* zoom: 1.2; */
}

.CommandeShow-alert {
  border: 1px dashed #d8343475 !important;
}

.CommandeShowheader {
  display: flex;
  align-items: center;
  background: #58ba48;
  font-weight: bold;
  color: #fff;
}

.CommandeShowheader-notification {
  display: flex;
  align-items: center;
  background: #ba4848;
  font-weight: bold;
  color: #fff;
  margin-bottom: 1px;
}

.nav-item-notification .nav-item-list {
  background: #ba4848;
}

.commandeid {
  padding: 15px;
  background: green;
  text-align: center;
  font-size: 22px;
  min-width: 55px;
}

.commandeid-notification {
  padding: 15px;
  background: #800000;
  text-align: center;
  font-size: 22px;
  min-width: 55px;
}


.CommandeShow-alert .CommandeShowheader {
  background: #9a48ba;
}

.CommandeShow-alert .commandeid {
  background: #8812b6;
}


.tablename {
  padding: 0 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tablename div {
  font-size: 20px;
}

.CommandeShowbody {
  display: flex;
  flex-direction: column;
}

.CommandeShowItem {

  margin: 1px;
  background: #c1d83475;
  display: flex;
}



.CommandeShowItem>span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  font-weight: bold;
  padding: 15px;
  background: #c1d8344a;
}

.CommandeShowItem>div {
  flex: 1;
  padding: 15px;
}


.groupname {
  color: #000;
  margin-top: 5px;
  padding: 10px;
}

.divcomment {
  background: #c9dc50;
  padding: 4px 9px;
  margin: 1px 0;
}



.State {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #e6e6e6;
}

.State>div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.State-item {
  margin: 5px 15px;
  height: 70px;
  min-width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex: 1;
}

.State-item-nbr {
  background: #000;
  color: #fff;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  font-size: 22px;

}

.State-item-titre {
  background: #343a40;
  color: #fff;
  padding: 15px;
  flex: 1;
  height: 54px;
  white-space: nowrap;
}

.State-item-active .State-item-nbr {
  background: green;
  color: #fff;
}

.State-item-active .State-item-titre {
  background: #65bd4b;
  color: #fff;
}


.titleitemrow {
  display: flex;
  align-items: center;
}

.titleitemrow>div {
  flex: 1;
  text-overflow: ellipsis;
}

.ticker {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  /* min-height: 250px; */
  /* max-width: 100% !important; */
}

.ticker>div.topticket {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.ticker img {
  width: 90px;
}

.print-item {
  display: flex;
  padding: 10px 0;
}


.print-item>.label {
  flex: 1;
}

.print-list>*+* {
  border-top: 1px solid #ccc;
}

.print-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
}

@media print {
  body {
    max-width: 400px !important;
    /* max-width: 100% !important; */
  }

  #root {
    min-height: auto !important;
    display: block !important;
    /* max-width: 100% !important; */
  }
}

.badgediv {
  position: absolute;
  top: 0;
  right: 35px;
  left: 0;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 7px;
}

.infoservable {
  position: absolute;
  z-index: 100;
  background: #fff;
  top: 5px;
  right: 5px;
  padding: 2px;
  border-radius: 50%;
  height: 27px;
  line-height: 0;
  font-size: 24px;
}

.ucfirst {
  text-transform: lowercase;
}

.ucfirst:first-letter {
  text-transform: uppercase;
}

.table td,
.table th {
  padding: 8px !important;
}



.container {
  max-width: 100% !important;
}


.CommandeShowItem-alertatt {
  background: #ccff00 !important;
}

.formclientauth{
  max-width: 400px;
  min-height: 300px;
  padding: 15px;
  margin: 15px;
  background: #fff;
  border: 1px solid #ccc;
}
.plat-autre{
  background: rgba(191, 191, 191, 0.5);
  padding: 5px;
  margin-top: 15px;
}
.plat-autre-item{
  display: flex;
}
.plat-autre-item > *+* {
  margin-left: 5px;
}